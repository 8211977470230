import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  tokens,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from '@fluentui/react-components'
import React from 'react'
import { MoreHorizontal } from '../BundledIcons'

const useOverflowMenuStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: 'center',
  },
})

export type OverflowMenuProps = {
  onTabSelect?: (tabId: string) => void
  tabs: TabData[]
}

/**
 * A menu for selecting tabs that have overflowed and are not visible.
 *
 * From {@link https://react.fluentui.dev/iframe.html?viewMode=docs&id=components-tablist--default#with-overflow}
 */
export const OverflowMenu = ({ onTabSelect, tabs }: OverflowMenuProps) => {
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>()

  const styles = useOverflowMenuStyles()

  const onItemClick = (tabId: string) => {
    onTabSelect?.(tabId)
  }

  if (!isOverflowing) {
    return null
  }

  return (
    <Menu hasIcons positioning={{ autoSize: true }}>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          className={styles.menuButton}
          ref={ref}
          icon={<MoreHorizontal />}
          aria-label={`${overflowCount} more tabs`}
          role="tab"
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList className={styles.menu}>
          {tabs.map(tab => (
            <OverflowMenuItem key={tab.id} tab={tab} onClick={() => onItemClick(tab.id)} />
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}

type TabData = {
  id: string
  name: string
  icon: React.ReactElement
}

type OverflowMenuItemProps = {
  tab: TabData
  onClick: (event: React.MouseEvent) => void
}

/**
 * A menu item for an overflow menu that only displays when the tab is not visible
 */
const OverflowMenuItem = ({ tab, onClick }: OverflowMenuItemProps) => {
  const isVisible = useIsOverflowItemVisible(tab.id)

  if (isVisible) {
    return null
  }

  return (
    <MenuItem key={tab.id} icon={tab.icon} onClick={onClick}>
      <div>{tab.name}</div>
    </MenuItem>
  )
}
