import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { ZoomInRegular } from '@fluentui/react-icons'
import { useDownloadFiles } from '@/hooks/filesHooks'

export type EmailPreviewDialogProps = {
  emailPreviewURL: string
  emailFileURL: string
  emailFilename: string
  open?: boolean
  onDismiss?: () => void
}

export const EmailPreviewDialog = ({
  emailPreviewURL,
  emailFileURL,
  emailFilename,
  open: controlledOpen,
  onDismiss,
}: EmailPreviewDialogProps) => {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { t } = useTranslation()
  const { commonDownload } = useDownloadFiles()

  useEffect(() => {
    if (controlledOpen || open) {
      setLoading(true)
      iframeRef.current?.contentWindow?.addEventListener('keydown', event => {
        if (event.key === 'Escape') {
          setOpen(false)
        }
      })
    }
  }, [controlledOpen, open])

  return (
    <Dialog
      open={controlledOpen ?? open}
      onOpenChange={(event, data) => {
        setOpen(data.open)
        if (!data.open) {
          onDismiss?.()
        }
      }}
    >
      {controlledOpen === undefined ? (
        <DialogTrigger>
          <button className="relative flex cursor-zoom-in border-none p-0">
            <iframe
              title="Email Preview"
              src={`${emailPreviewURL}?short=true`}
              className="pointer-events-none box-border h-48 w-96 max-w-full border-none bg-white"
            />
            <div className="absolute h-full w-full bg-black/50"></div>
            <ZoomInRegular className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 text-4xl text-white" />
          </button>
        </DialogTrigger>
      ) : (
        <></>
      )}
      <DialogSurface
        className="!max-w-none !bg-transparent backdrop-blur-sm md:!max-w-[90vw]"
        onClick={() => {
          setOpen(false)
          onDismiss?.()
        }}
      >
        <DialogBody>
          <DialogTitle className="select-none">
            {t('item_chat.email_preview.title')}: {emailFilename}
          </DialogTitle>
          <DialogContent className="relative" onClick={event => event.stopPropagation()}>
            {loading && <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
            <iframe
              ref={iframeRef}
              onLoad={() => setLoading(false)}
              src={emailPreviewURL}
              title="Email Preview"
              className="box-border h-[75vh] w-full border-none bg-white"
            />
          </DialogContent>
          <DialogActions onClick={event => event.stopPropagation()}>
            <DialogTrigger disableButtonEnhancement>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
            <Button onClick={() => commonDownload([{ url: emailFileURL, filename: emailFilename }])}>
              {t('item_chat.email_preview.download_file')}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
