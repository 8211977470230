import { useQuery } from '@tanstack/react-query'
import { useSelector, useStore } from 'react-redux'
import { appendQueryParamsToURL, getCredentialsConfig } from '../common/src/helpers/requestHelper'
import { itemHelper, stateHelper } from '../common/src/helpers'
import {
  isInitializedRequestForPMResource,
  isPendingRequestForPMResource,
} from '../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../common/src/constants'
import { useDeferredValue, useMemo } from 'react'
import { useRouteId } from '@/hooks/useRouteId'
import { ROUTE_ID } from '@/routes/routeIdList'
import { getSearchedItems } from '@/common/src/selectors/searchSelectors'
import { getItemFiltersCount } from '@/common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '@/common/src/reducers/filtersKeys'

export const projectCounterKeys = {
  all: ['projects', 'counters'] as const,
}

type ProjectCountersBase = {
  0: number
  1: number
  2: number
  3: number
  overdue?: number
  completed?: number
  incomplete?: number
}

export type ProjectCounters = Required<ProjectCountersBase>

export type AllProjectCountersWithOverdue = {
  [projectId: string]: ProjectCounters
}

export const useAllProjectCounters = (enabled = true, refetchOnMount = true) => {
  const store = useStore()
  const routeId = useRouteId()
  // Disable selector while !enabled, to avoid unnecessary rerenders
  const allItems = useSelector(enabled ? stateHelper.getAllItemsMap : () => null)
  const allItemsDeferred = useDeferredValue(allItems)
  const isSearchView = routeId === ROUTE_ID.SEARCH || routeId === ROUTE_ID.SEARCH_APP
  const searchedItems = useSelector(getSearchedItems)
  const searchedItemsDeferred = useDeferredValue(searchedItems)
  const searchFilterCount = useSelector(state => getItemFiltersCount(state, FILTER_REDUCER_KEYS.SEARCH))
  const searchFilterCountDeferred = useDeferredValue(searchFilterCount)

  const query = useQuery({
    queryKey: projectCounterKeys.all,
    queryFn: async () => {
      const baseUrl = '/api/v1/project/counters/'
      const params = { overdue: true, completed: true, incomplete: true }
      const response = await fetch(appendQueryParamsToURL(baseUrl, params)(store.getState()), {
        credentials: getCredentialsConfig(),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return (await response.json()) as AllProjectCountersWithOverdue
    },
    refetchOnMount,
    enabled,
  })

  const getDefaultCounters = (): ProjectCounters => ({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    overdue: 0,
    completed: 0,
    incomplete: 0,
  })

  const data = useMemo(() => {
    if (!enabled || !query.data) return undefined
    const state = store.getState()
    const computedData = { ...query.data }
    const now = new Date()
    Object.keys(computedData).forEach(projectId => {
      const isItemSummariesInitialized = (isInitializedRequestForPMResource as (...args: any) => boolean)(
        state,
        PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT,
        projectId
      )
      const isItemSummariesPending = (isPendingRequestForPMResource as (...args: any) => boolean)(
        state,
        PM_API_RESOURCES.ITEM_SUMMARIES_IN_PROJECT,
        projectId
      )
      const showSearchProjectCounts = isSearchView && searchFilterCountDeferred
      const showLocalProjectCounts = isItemSummariesInitialized && !isItemSummariesPending
      if (!showSearchProjectCounts && !showLocalProjectCounts) {
        return
      }
      const items = isSearchView ? searchedItemsDeferred : allItemsDeferred
      computedData[projectId] = getDefaultCounters()
      const itemsInProject = items?.filter((item: any) => itemHelper.belongsToProjectWithId(item, +projectId))
      itemsInProject?.forEach((item: any) => {
        const quadrant = itemHelper.getQuadrant(item) as 0 | 1 | 2 | 3
        if (quadrant === null) {
          return
        }
        if (itemHelper.isCompleted(item)) {
          return
        }
        computedData[projectId][quadrant]++
        const dueDate = itemHelper.getDueDate(item)
        const isOverdue = dueDate ? dueDate < now : false
        if (isOverdue) {
          computedData[projectId].overdue++
        }
        if (itemHelper.isCompleted(item)) {
          computedData[projectId].completed++
        } else {
          computedData[projectId].incomplete++
        }
      })
    })
    return computedData
  }, [allItemsDeferred, enabled, isSearchView, query.data, searchFilterCountDeferred, searchedItemsDeferred, store])

  return useMemo(
    () => ({
      ...query,
      data,
    }),
    [data, query]
  )
}
