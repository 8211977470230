import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getMultipleSelectedItems, isItemMultipleSelectionOn } from '../../selectors/uiSelectors'
import { useDispatch } from 'react-redux'
import {
  clearItemMultipleSelection,
  setItemsSelectedInMultiSelection,
  setMultipleSelectionOn,
} from '../../actions/uiActions'
import { useMultiItemOptions } from '../../hooks/itemContextualMenu'
import { itemHelper } from '../../common/src/helpers'
import { Map } from 'immutable'
import {
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { useRef } from 'react'
import { Dismiss, MultiselectLtr, SelectAllOff, SelectAllOn } from '../BundledIcons'
import { isSelectAllHotKey, useHotkey } from '../../hooks/useHotkey.js'

export const TopBarMultiSelectButton = ({ allItems, appearance }) => {
  const primaryActionButtonRef = useRef()
  const { t } = useTranslation()
  const multipleSelection = useSelector(isItemMultipleSelectionOn)
  const multipleSelectedItemIds = useSelector(getMultipleSelectedItems)
  const dispatch = useDispatch()

  const handleToggleMultipleSelection = () => {
    dispatch(setMultipleSelectionOn(!multipleSelection))
    if (multipleSelection) {
      dispatch(clearItemMultipleSelection())
    }
  }

  const multiItemOptions = useMultiItemOptions()

  const selectAllItems = () => {
    dispatch(setMultipleSelectionOn(true))
    const itemIds = Map.isMap(allItems) ? allItems.keys() : allItems.map(item => itemHelper.getId(item))
    dispatch(setItemsSelectedInMultiSelection(itemIds))
  }

  useHotkey(isSelectAllHotKey, selectAllItems, true)

  const selectAllOption = (
    <MenuItem onClick={selectAllItems} icon={<SelectAllOn />}>
      {t('item.multiple_selection.select_all')}
    </MenuItem>
  )

  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        {triggerProps => (
          <Tooltip
            content={t('item.multiple_selection.toggle_button_tooltip')}
            relationship="label"
            positioning={{ target: primaryActionButtonRef.current }}
          >
            <SplitButton
              primaryActionButton={{ ref: primaryActionButtonRef, onClick: handleToggleMultipleSelection }}
              icon={<MultiselectLtr />}
              menuButton={triggerProps}
              appearance={multipleSelection ? 'primary' : appearance}
            />
          </Tooltip>
        )}
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {!multipleSelection && (
            <MenuItem
              onClick={() => {
                dispatch(setMultipleSelectionOn(true))
              }}
              icon={<MultiselectLtr />}
            >
              {t('item.multiple_selection.start_selection')}
            </MenuItem>
          )}
          {multipleSelection && (
            <MenuItem
              onClick={() => {
                dispatch(setMultipleSelectionOn(false))
                dispatch(clearItemMultipleSelection())
              }}
              icon={<Dismiss />}
            >
              {t('item.multiple_selection.end_selection')}
            </MenuItem>
          )}
          {allItems && selectAllOption}
          {multipleSelection && multipleSelectedItemIds.size > 0 && (
            <MenuItem
              onClick={() => {
                dispatch(clearItemMultipleSelection())
              }}
              icon={<SelectAllOff />}
            >
              {t('item.multiple_selection.clear_selection')}
            </MenuItem>
          )}
          {multipleSelection && (
            <>
              <MenuDivider />
              <MenuGroup>
                <MenuGroupHeader>
                  {t('item.multiple_selection.count', { count: multipleSelectedItemIds.size })}
                </MenuGroupHeader>
                {multiItemOptions.map(option =>
                  option.type === 'divider' ? (
                    <MenuDivider key={option.key} />
                  ) : option.subMenuProps ? (
                    <Menu key={option.key}>
                      <MenuTrigger disableButtonEnhancement>
                        <MenuItem icon={option.icon}>{option.text}</MenuItem>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          {option.subMenuProps.items.map(subItem => (
                            <MenuItem key={subItem.key} onClick={subItem.onClick} icon={subItem.icon}>
                              {subItem.text}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  ) : (
                    <MenuItem key={option.key} onClick={option.onClick} icon={option.icon}>
                      {option.text}
                    </MenuItem>
                  )
                )}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
