import React, { memo, useCallback, useReducer } from 'react'
import { cn } from '@appfluence/classnames'
import { Label } from '../../components/text/Text'
import { Image, ImageFit } from '@fluentui/react/lib/Image'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import {
  FullScreenModalContainer,
  FullScreenModalSection,
  FullScreenModalSectionBody,
  FullScreenModalSectionHeader,
} from '../modal/FullScreenModalContainer'
import { Spinner } from '../../components/basic/Loading'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useInstantiatePublicTemplateAdvanced } from '../../hooks/projectTemplateHooks'
import { Downloads } from '../../components/projectTemplates/Downloads'
import { useTranslation } from 'react-i18next'
import { usePublicProjectTemplate } from '@/queries/projectTemplates'

const ACTION_TYPE = {
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_LOADING: 'SET_LOADING',
}

const initialState = {
  loading: false,
  errorMessage: false,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTION_TYPE.SET_ERROR_MESSAGE:
      state.errorMessage = payload
      break
    case ACTION_TYPE.SET_LOADING:
      state.loading = payload
      state.errorMessage = ''
      break
    case ACTION_TYPE.SET_STARTING_DATE:
      state.startingDate = payload
      break
    default:
      break
  }
  return { ...state }
}

export const NewProjectFromPublicTemplateModal = memo(({ history, location, match, ...rest }) => {
  const [state, localDispatch] = useReducer(reducer, { ...initialState })
  const { loading, errorMessage } = state
  const instantiatePublicTemplate = useInstantiatePublicTemplateAdvanced()
  const { t } = useTranslation()

  const templateID = getMatchParameterInteger(match, 'template_id')

  const { data: template, isLoading } = usePublicProjectTemplate(templateID)

  const onCreate = useCallback(
    projectID => {
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(projectID)
      history.push(path)
    },
    [history]
  )

  const create = useCallback(async () => {
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: true })
    const response = await instantiatePublicTemplate({
      templateID,
      notify: false,
      delayToOpen: 150,
      onCreate,
    })
    localDispatch({ type: ACTION_TYPE.SET_LOADING, payload: false })

    const { error } = response
    if (error) {
      localDispatch({
        type: ACTION_TYPE.SET_ERROR_MESSAGE,
        payload: t('project_template.created_unsuccessfully'),
      })
    }
  }, [instantiatePublicTemplate, templateID, onCreate, t])

  const sectionCN = cn('lg:m-20 overflow-auto h-full', {
    'justify-center': isLoading,
  })

  const bodyCN = cn('items-center lg:flex-row lg:flex-none lg:w-full lg:max-w-full lg:m-0 lg:overflow-hidden lg:flex-1')

  return (
    <FullScreenModalContainer bodyClassName={bodyCN} {...rest}>
      {isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner size="large" />
        </div>
      )}
      {!isLoading && (
        <>
          <FullScreenModalSection className={sectionCN}>
            {!!template.image && <img src={template.image} className="mt-4 h-36" alt="Template Pictogram" />}
            <FullScreenModalSectionHeader>
              <div>{template.name}</div>
            </FullScreenModalSectionHeader>
            <FullScreenModalSectionBody>
              {template.short_description && (
                <>
                  <Label className="mt-3 font-bold">Notes</Label>
                  <div className="text-pm-neutral-dark">{template.short_description}</div>
                </>
              )}
              {!!template.downloads && (
                <>
                  <Label className="mt-3 font-bold">{t('project_template.downloads')}</Label>
                  <Downloads count={template.downloads} className="mt-2" />
                </>
              )}
              {template.image_url && (
                <Image src={template.image_url} imageFit={ImageFit.centerContain} className="mt-6 sm:h-[200px]" />
              )}
              <div className="mt-5 h-8">
                {loading ? (
                  <Spinner size="medium" />
                ) : (
                  <PrimaryButton onClick={create} className="w-full">
                    {t('project_template.instantiate_template')}
                  </PrimaryButton>
                )}
              </div>
              {errorMessage && <div className="mt-2 text-pm-negative">{errorMessage}</div>}
            </FullScreenModalSectionBody>
          </FullScreenModalSection>
          <Preview readonlyURL={template.readonly} />
        </>
      )}
    </FullScreenModalContainer>
  )
})

const Preview = React.memo(({ readonlyURL }) => {
  const lg = 'lg:w-3/4 lg:max-w-full lg:w-full lg:h-full lg:m-0'
  const base = 'mt-5 h-[400px] w-full max-w-lg'
  const CN = cn(base, lg)
  return (
    <div className={CN}>
      <iframe src={readonlyURL} className="h-full w-full border-none" title="Preview" />
    </div>
  )
})
