import { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Coachmark } from '@fluentui/react/lib/Coachmark'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { userHelper } from '../../common/src/helpers'
import { isOneOnOneFixedQueryParam } from '../../helpers/queryParamsHelper'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { TopBarAddButton } from '../../components/buttons/TopBarAddButton'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { OneOnOnePersona } from './OneOnOnePersona'
import { useUserForEmail } from '../../common/src/hooks/usersHooks'
import { OneOnOneUserSelectionPanel } from './OneOnOneUserSelectionPanel'
import { OneOnOneFiltersPanel } from '../filters/OneOnOneFiltersPanel'
import { FilterButton } from '../../components/buttons/FilterButton'
import { getItemFiltersCount } from '../../common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { OptionsMenuButton } from '../../components/buttons/OptionsMenuButton'
import { CYPRESS_ID } from '../../constants/cypress'
import { useTeamsStaticTabMenuProps } from '../../components/layout/TeamsHeader'
import { useTranslation } from 'react-i18next'
import { setDefaultFilterItems } from '../../common/src/actions/filtersActions'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
import { PrintButton } from '../../components/buttons/PrintButton'

const topBarStyles = {
  leftContainer: {
    flex: 1,
  },
  rightContainer: {
    flex: 'inherit',
  },
}

export const OneOnOneTopBar = memo(
  ({
    collaboratorOptions,
    onChangeCollaborator,
    selected,
    isEmbedded = false,
    inApp = false,
    showFiltersButton = true,
    showAddButton,
    promotionCallout,
    onCreateItem = _.identity,
    inviteUserButton,
    items,
  }) => {
    const dispatch = useDispatch()
    const user = useUserForEmail(selected)
    const filtersCount = useSelector(state => getItemFiltersCount(state, FILTER_REDUCER_KEYS.ONE_ON_ONE))
    const clearFilters = () => dispatch(setDefaultFilterItems(FILTER_REDUCER_KEYS.ONE_ON_ONE))
    const [personaElement, setPersonaElement] = useState(null)
    const narrowWidth = useNarrowWidth()
    const outsideAppMenuProps = useTeamsStaticTabMenuProps()
    const { t } = useTranslation()

    const [isOpenPanel, setIsOpenPanel] = useState(false)
    const showPanel = useCallback(() => {
      setIsOpenPanel(true)
    }, [setIsOpenPanel])
    const hidePanel = useCallback(() => {
      setIsOpenPanel(false)
    }, [setIsOpenPanel])

    const [isOpenFiltersPanel, setIsOpenFiltersPanel] = useState(false)
    const showFiltersPanel = useCallback(() => {
      setIsOpenFiltersPanel(true)
    }, [setIsOpenFiltersPanel])
    const hideFiltersPanel = useCallback(() => {
      setIsOpenFiltersPanel(false)
    }, [setIsOpenFiltersPanel])

    const addInviteButton = useMemo(() => {
      if (!selected) {
        return false
      }
      const selectedOption = collaboratorOptions.find(option => option.email === selected)
      return selectedOption && selectedOption.user && !userHelper.isPMUser(selectedOption.user)
    }, [selected, collaboratorOptions])

    const onSelectUser = useCallback(
      email => {
        onChangeCollaborator(email)
      },
      [onChangeCollaborator]
    )

    const fixed = isOneOnOneFixedQueryParam()
    const title = narrowWidth ? t('one_on_one.top_bar_short_title') : t('one_on_one.top_bar_title')
    const leftComponents = (
      <>
        <OneOnOnePersona
          id="oneOnOneTopBar_selectedUser"
          className="ml-2 min-w-0 max-w-xs flex-1"
          data-automation-id={CYPRESS_ID.ONE_ON_ONE_SELECTION_BOX}
          user={user}
          onClick={showPanel}
          readOnly={fixed}
          ref={setPersonaElement}
        />
        {!narrowWidth && (
          <Coachmark
            target={personaElement}
            positioningContainerProps={{
              directionalHint: showAddButton ? DirectionalHint.bottomCenter : DirectionalHint.rightCenter,
              doNotLayer: false,
            }}
          />
        )}
        {addInviteButton && inviteUserButton}
      </>
    )

    const rightComponents = (
      <>
        {showAddButton && selected && (
          <TopBarAddButton
            text={t('one_on_one.add_shared_task')}
            onClick={onCreateItem}
            data-automation-id={CYPRESS_ID.ONE_ON_ONE_ADD_TASK_TOP}
          />
        )}
        <TopBarMultiSelectButton allItems={items} />
        {showFiltersButton && (
          <FilterButton
            id="oneOnOneTopBar_filtersButton"
            size={16}
            key={'FiltersButton'}
            tooltip={t('one_on_one.top_bar_filter_button_tooltip')}
            filtersCount={filtersCount}
            onClick={showFiltersPanel}
            onClear={filtersCount && clearFilters}
          />
        )}
        <PrintButton items={items} className="!hidden md:!flex" />
        {!inApp && !isEmbedded && (
          <OptionsMenuButton menuProps={outsideAppMenuProps} positioning={{ autoSize: true }} />
        )}
      </>
    )

    return (
      <>
        <TopRightBar
          className="py-3"
          styles={topBarStyles}
          key={'OneOnOneTopBar'}
          inApp={inApp}
          title={title}
          leftSubcomponents={leftComponents}
          rightSubcomponents={rightComponents}
          promotionCallout={promotionCallout}
        />
        <OneOnOneUserSelectionPanel
          email={selected}
          onChange={onSelectUser}
          isOpen={isOpenPanel}
          onDismiss={hidePanel}
        />
        <OneOnOneFiltersPanel isOpen={isOpenFiltersPanel} onDismiss={hideFiltersPanel} />
      </>
    )
  }
)

OneOnOneTopBar.propTypes = {
  collaboratorOptions: PropTypes.array,
  onChangeCollaborator: PropTypes.func.isRequired,
  selected: PropTypes.string,
  inApp: PropTypes.bool,
  showAddButton: PropTypes.bool,
  onCreateItem: PropTypes.func,
}
