import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuSplitGroup,
  MenuTrigger,
} from '@fluentui/react-components'
import {
  BranchCompare,
  Delete,
  FolderArrowRight,
  FolderLink,
  LinkAdd,
  MoreHorizontal,
  TabDesktop,
  TaskListAdd,
  Warning,
  WindowNew,
} from '@/components/BundledIcons'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useState } from 'react'
import { itemHelper, projectHelper, stateHelper } from '@/common/src/helpers'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '@/common/src/eventTracking/amplitudeEvents'
import { AlertModalContext, MoveItemModalContext } from '@/contexts'
import { useDispatch, useSelector } from 'react-redux'
import { useAdjustPathnameOnDeletedItem, useCreateLinkedItem } from '@/hooks/itemHooks'
import { VersionControlModal } from '@/views/ItemDetail/ItemDetailSubcomponents'
import { getItemLink } from '@/common/src/constants'
import { openExternalLink } from '@/utils/externalLinkHandler'
import { QuadrantSelector } from '@/components/input/quadrant/QuadrantSelector'
import { queryParamsHelper } from '@/helpers'
import { isMobile } from '@/common/src/helpers/platformHelper'
import { isElectronApp } from '@/integrations/electron'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { useAtom } from 'jotai'
import { hideConfirmationDeleteItemAtom } from '@/atoms'
import { useConvertItemToProjectDialog, useProjectLinkSelectorDialog } from '@/typedContexts'

export const ItemDetailOptionsButton = ({
  item,
  itemConflictsRequired = false,
  changeProperty,
  toggleSeeConflicts,
  readOnly = false,
}) => {
  const { t } = useTranslation()
  const { setProps: setAlertModalProps } = useContext(AlertModalContext)
  const moveItemModalContext = useContext(MoveItemModalContext)
  const dispatch = useDispatch()
  const adjustPathnameOnDeletedItem = useAdjustPathnameOnDeletedItem()
  const [isVersionControlOpen, setIsVersionControlOpen] = useState(false)
  const itemId = itemHelper.getId(item)
  const itemLink = getItemLink(itemId)
  const isInbox = itemHelper.isInbox(item)
  const isPlus = useSelector(state => stateHelper.isInboxPlusItem(state, item))
  const quadrant = itemHelper.getQuadrant(item)
  const projectIdd = itemHelper.getProjectIdd(item)
  const project = useSelector(state => stateHelper.getProject(state, projectIdd))
  const canChangeQuadrant = !isInbox && !isPlus
  const isEmbeddedOnOutlook = queryParamsHelper.isEmbeddedOnOutlook()
  const platformIsMobile = isMobile()
  const hide_chrome = queryParamsHelper.hideChrome()
  const isEmbeddedOnTeams = queryParamsHelper.isEmbeddedOnTeams()
  const [hideConfirmationDeleteItem, setHideConfirmationDeleteItem] = useAtom(hideConfirmationDeleteItemAtom)
  const { openDialog: openConvertItemToProjectDialog } = useConvertItemToProjectDialog()

  const createLinkedItem = useCreateLinkedItem(item)

  const moveItem = () => {
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.MOVE_ITEM))

    moveItemModalContext.setMoveItemModalState({
      isDisplayed: true,
      item: item,
      initProject: isInbox || isPlus ? null : project,
      initQuadrant: isInbox || isPlus ? null : quadrant,
    })
  }

  const handlePopOut = () => {
    const adjustedWidth = window.innerWidth / 1.5
    const adjustedHeight = window.innerHeight / 1.25
    const windowConfig = 'width=' + adjustedWidth + ',height=' + adjustedHeight
    const paramsToHideChrome = '?hide_chrome=1'
    const itemID = itemHelper.getId(item)
    const link = getItemLink(itemID) + paramsToHideChrome
    window.open(link, '', windowConfig)
  }

  const handleNewTab = event => {
    event.preventDefault()
    openExternalLink(itemLink)
  }

  const handleDeleteItem = () => {
    const deleteItem = () => {
      changeProperty(itemHelper.KEYS.STATE, itemHelper.STATE.DELETED, true, false)
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.DELETE_ITEM))
      adjustPathnameOnDeletedItem(itemHelper.getId(item))
    }

    if (hideConfirmationDeleteItem) {
      deleteItem()
      return
    }

    setAlertModalProps({
      open: true,
      title: t('item_detail.delete_alert_title'),
      subText: t('item_detail.delete_alert_subtitle', { name: itemHelper.getName(item) }),
      primaryActionText: t('general.delete'),
      onPrimaryActionClick: deleteItem,
      onYesAlwaysClick: () => {
        setHideConfirmationDeleteItem(true)
        deleteItem()
      },
    })
  }

  const moveItemMenuItem = (
    <MenuItem icon={<FolderArrowRight />} onClick={moveItem}>
      {t('item_detail.move_item')}
    </MenuItem>
  )

  const dismissVersionControlModal = useCallback(() => {
    setIsVersionControlOpen(false)
  }, [])

  const { openDialog: openProjectLinkSelectorDialog } = useProjectLinkSelectorDialog()

  const handleAddLinkToProject = () => {
    openProjectLinkSelectorDialog(item)
  }

  return (
    <>
      <Menu positioning={{ autoSize: true }}>
        <Tooltip content={t('general.options')} relationship="label">
          <MenuTrigger disableButtonEnhancement>
            <MenuButton icon={<MoreHorizontal />} appearance="subtle" data-testid="ItemDetail_OptionsButton" />
          </MenuTrigger>
        </Tooltip>
        <MenuPopover>
          <MenuList>
            {!readOnly && (
              <MenuItem icon={<LinkAdd />} onClick={createLinkedItem}>
                {t('item.create_linked_item')}
              </MenuItem>
            )}
            {!readOnly &&
              (canChangeQuadrant ? (
                <Menu>
                  <MenuSplitGroup>
                    {moveItemMenuItem}
                    <MenuTrigger disableButtonEnhancement>
                      <MenuItem aria-label={t('item_detail.move_to_quadrant_action')} />
                    </MenuTrigger>
                  </MenuSplitGroup>
                  <MenuPopover>
                    <MenuList>
                      <MenuGroup>
                        <MenuGroupHeader>{t('item_detail.move_to_quadrant_action')}</MenuGroupHeader>
                        {[0, 1, 2, 3].map(q => (
                          <MenuItem
                            key={q}
                            onClick={() => changeProperty(itemHelper.KEYS.QUADRANT, q)}
                            icon={
                              <QuadrantSelector
                                size={18}
                                project={project}
                                quadrant={q}
                                isItem={true}
                                readOnly={true}
                              />
                            }
                          >
                            {projectHelper.getSafeQuadrantName(project, q)}
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                moveItemMenuItem
              ))}
            <MenuItem icon={<TaskListAdd />} onClick={() => openConvertItemToProjectDialog(itemId)}>
              {t('item.contextual_menu.convert_to_project')}
            </MenuItem>
            <MenuItem icon={<FolderLink />} onClick={handleAddLinkToProject}>
              {t('item.add_link_project')}
            </MenuItem>
            <MenuItem icon={<BranchCompare />} onClick={() => setIsVersionControlOpen(true)}>
              {t('item_detail.version_control_action')}
            </MenuItem>
            {!platformIsMobile && !hide_chrome && (
              <MenuItem icon={<WindowNew />} onClick={handlePopOut}>
                {t('item_detail.pop_out_item')}
              </MenuItem>
            )}
            {!platformIsMobile && !hide_chrome && !isEmbeddedOnTeams && !isElectronApp() && (
              <MenuItemLink icon={<TabDesktop />} href={itemLink} onClick={handleNewTab}>
                {t('item_detail.new_tab_item')}
              </MenuItemLink>
            )}
            <MenuItem icon={<Warning />} onClick={toggleSeeConflicts}>
              {itemConflictsRequired ? t('item_detail.conflicts.stop') : t('item_detail.conflicts.start')}
            </MenuItem>
            {!readOnly && !isEmbeddedOnOutlook && (
              <MenuItem icon={<Delete />} onClick={handleDeleteItem} data-testid="ItemDetail_DeleteItem">
                {t('item_detail.delete_action')}
              </MenuItem>
            )}
          </MenuList>
        </MenuPopover>
      </Menu>
      <VersionControlModal
        itemID={itemHelper.getId(item)}
        onDismiss={dismissVersionControlModal}
        isOpen={isVersionControlOpen}
      />
    </>
  )
}
