import { memo, useCallback, useDeferredValue, useEffect, useState, useRef } from 'react'
import { useFilteredAndSortedProjectsArray } from '../../common/src/hooks/projectHooks'
import { projectHelper } from '../../common/src/helpers'
import { cn } from '@appfluence/classnames'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { getPreferredMatrixViewMode, getProjectsPanelShowItemCounts } from '../../selectors/uiSelectors'
import { getRelativePathToMatrixBasedOnMode } from '../../helpers/routeHelper'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { projectsView as initProjectsView } from '../../actions/viewInitializationActions'
import {
  Button,
  CounterBadge,
  Input,
  makeStyles,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemCheckbox,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  ToggleButton,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { PinFilled, StarFilled } from '@fluentui/react-icons'
import {
  Calculator,
  CalculatorArrowClockwise,
  Filter,
  FilterDismiss,
  Options,
  PanelLeftContract,
  PanelLeftExpand,
} from '../BundledIcons'
import {
  PROJECTS_SORT_TYPE,
  setDefaultFilterProjects,
  setProjectsTextFilter,
} from '../../common/src/actions/filtersActions'
import {
  getProjectFiltersCount,
  getProjectsSortType,
  getProjectsTextFilter,
} from '../../common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useOnProjectDragEnd } from '../../hooks/projectHooks'
import { useAllProjectCounters } from '../../queries/projectCounters'
import { setProjectsPanelShowItemCounts } from '../../actions/uiActions'
import { ProjectOptionsMenu } from '../../views/project/ProjectOptionsButton'
import { usePositioningMouseTarget } from '@fluentui/react-positioning'
import { ProjectsFiltersPanel } from '../../views/filters/ProjectsFiltersPanel'
import { useSelectedProjectId } from '../../hooks/PMHooks'
import { isSearchProjectLeftPanelHotKey, useHotkey } from '@/hooks/useHotkey'

const useStyles = makeStyles({
  expandButton: {
    marginRight: '0.5rem',
  },
})

const SORT_MODES_TRANSLATION_KEYS = {
  [PROJECTS_SORT_TYPE.INDEX]: 'project_filters.manually',
  [PROJECTS_SORT_TYPE.NAME]: 'project_filters.alphabetically',
  [PROJECTS_SORT_TYPE.TIMESTAMP]: 'project_filters.modification_date',
}

const SHOW_ITEM_COUNTS = 'showItemCounts'

const ProjectsPanel_ = ({
  collapsed = false,
  collapsePanel,
  expandPanel,
  refetchCounters,
  isLoadingCounters,
  counters,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sortMode = useSelector(state => getProjectsSortType(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))
  const sortedFilteredProjects = useFilteredAndSortedProjectsArray(FILTER_REDUCER_KEYS.PROJECTS_PANEL)
  const pinnedProjects = sortedFilteredProjects.filter(projectHelper.isPinned)
  const starredProjects = sortedFilteredProjects.filter(
    project => projectHelper.isStarred(project) && !projectHelper.isPinned(project)
  )
  const notStarredProjects = sortedFilteredProjects.filter(
    project => !projectHelper.isStarred(project) && !projectHelper.isPinned(project)
  )

  const filtersCount = useSelector(state => getProjectFiltersCount(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))

  // Internal state for optimistic reordering
  const [currentProjects, setCurrentProjects] = useState(sortedFilteredProjects)
  useEffect(() => {
    setCurrentProjects(sortedFilteredProjects)
  }, [sortedFilteredProjects])

  const selectedProjectId = useSelectedProjectId()
  const showItemCounts = useSelector(getProjectsPanelShowItemCounts)

  const searchQuery = useSelector(state => getProjectsTextFilter(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))
  const deferredSearchQuery = useDeferredValue(searchQuery)
  const setSearchQuery = useCallback(
    newSearchQuery => dispatch(setProjectsTextFilter(newSearchQuery, FILTER_REDUCER_KEYS.PROJECTS_PANEL)),
    [dispatch]
  )

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false)

  const hideFiltersPanel = useCallback(() => setFiltersPanelOpen(false), [])

  useEffect(() => {
    dispatch(initProjectsView())
  }, [dispatch])

  const sections = deferredSearchQuery
    ? [{ key: 'search', title: t('projects_panel.search_results'), projects: sortedFilteredProjects }]
    : [
        ...(pinnedProjects.length && sortMode !== PROJECTS_SORT_TYPE.INDEX
          ? [
              {
                key: 'pinned',
                title: (
                  <span className="flex items-center">
                    <PinFilled className="text-md ml-0.5 mr-2" />
                    {t('projects_panel.pinned')}
                  </span>
                ),
                projects: pinnedProjects,
              },
            ]
          : []),
        ...(starredProjects.length && sortMode !== PROJECTS_SORT_TYPE.INDEX
          ? [
              {
                key: 'starred',
                title: (
                  <span className="flex items-center">
                    <StarFilled className="text-md ml-0.5 mr-2 text-yellow-400" />
                    {t('projects_panel.starred')}
                  </span>
                ),
                projects: starredProjects,
              },
            ]
          : []),
        {
          key: 'sorted',
          title: t(SORT_MODES_TRANSLATION_KEYS[sortMode]),
          projects: sortMode === PROJECTS_SORT_TYPE.INDEX ? currentProjects : notStarredProjects,
        },
      ]

  const onDragEnd = useOnProjectDragEnd({ currentProjects, setCurrentProjects })

  const [contextualMenuOpen, setContextualMenuOpen] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = usePositioningMouseTarget()
  const [contextualMenuProject, setContextualMenuProject] = useState(null)

  const onContextMenuOpenChange = useCallback((ev, { open }) => {
    setContextualMenuOpen(open)
  }, [])

  const searchRef = useRef(null)
  const containerRef = useRef(null)
  const focusSearchInput = useCallback(() => {
    searchRef.current?.focus()
  }, [searchRef])
  useHotkey(isSearchProjectLeftPanelHotKey, focusSearchInput, true)

  const onKeyDown = evt => {
    if (evt.key === 'Enter') {
      containerRef.current?.querySelector('a')?.click?.()
    }
  }

  return (
    <aside className="mt-1 flex h-full flex-col">
      <header className="relative flex w-full justify-center">
        {showItemCounts && isLoadingCounters && <Spinner size="extra-small" className="absolute -bottom-9 right-3" />}
        {!collapsed && (
          <Input
            appearance="filled-darker"
            className="mx-1 w-0 flex-1"
            input={{ className: 'placeholder-shown:text-ellipsis' }}
            placeholder={t('projects_panel.search_placeholder')}
            value={searchQuery}
            onChange={(ev, data) => setSearchQuery(data.value)}
            onKeyDown={onKeyDown}
            ref={searchRef}
          />
        )}
        {!collapsed && (
          <Menu
            checkedValues={{ [SHOW_ITEM_COUNTS]: showItemCounts ? [SHOW_ITEM_COUNTS] : [] }}
            positioning={{ autoSize: true }}
          >
            <Tooltip content="Projects Panel Options" relationship="label" positioning="below">
              <MenuTrigger disableButtonEnhancement>
                <ToggleButton
                  appearance="subtle"
                  icon={
                    <span className="relative flex h-full w-full items-center justify-center">
                      <Options />
                      {!!filtersCount && (
                        <CounterBadge size="small" className="!absolute -right-1 -top-1">
                          {filtersCount}
                        </CounterBadge>
                      )}
                    </span>
                  }
                  checked={!!filtersCount}
                />
              </MenuTrigger>
            </Tooltip>
            <MenuPopover>
              <MenuList>
                <MenuItem icon={<Filter />} onClick={() => setFiltersPanelOpen(true)}>
                  {t('projects_panel.show_filters_panel')}
                  {!!filtersCount && ` (${filtersCount})`}
                </MenuItem>
                {!!filtersCount && (
                  <MenuItem
                    icon={<FilterDismiss />}
                    onClick={() => {
                      dispatch(setDefaultFilterProjects(FILTER_REDUCER_KEYS.PROJECTS_PANEL))
                    }}
                  >
                    {t('projects_panel.clear_filters')}
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItemCheckbox
                  name={SHOW_ITEM_COUNTS}
                  value={SHOW_ITEM_COUNTS}
                  onClick={() => dispatch(setProjectsPanelShowItemCounts(!showItemCounts))}
                  icon={<Calculator />}
                >
                  {t('projects_panel.show_item_counts')}
                </MenuItemCheckbox>
                {showItemCounts && (
                  <>
                    <MenuDivider />
                    <MenuItem onClick={refetchCounters} icon={<CalculatorArrowClockwise />}>
                      {t('projects_panel.refresh_counts_tooltip')}
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
        <Tooltip
          content={collapsed ? t('projects_panel.expand_panel') : t('projects_panel.collapse_panel')}
          relationship="label"
          positioning="below"
        >
          <Button
            className={styles.expandButton}
            appearance="subtle"
            size={collapsed ? 'large' : 'medium'}
            icon={collapsed ? <PanelLeftExpand /> : <PanelLeftContract />}
            onClick={() => {
              if (collapsed) expandPanel()
              else collapsePanel()
            }}
          />
        </Tooltip>
      </header>
      <nav ref={containerRef} className="flex flex-1 flex-col gap-3 overflow-y-auto py-2">
        {sections.map(({ key, title, projects }) => (
          <DragDropContext key={key} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="flex flex-col gap-1 px-1">
                  {!collapsed && <span className="ml-3 text-base text-pm-theme-primary">{title}</span>}
                  {projects.map((project, index) => (
                    <ProjectListCell
                      key={projectHelper.getIdd(project)}
                      collapsed={collapsed}
                      project={project}
                      index={index}
                      selectedProjectId={selectedProjectId}
                      draggable={sortMode === PROJECTS_SORT_TYPE.INDEX}
                      showItemCounts={showItemCounts}
                      counters={counters?.[projectHelper.getIdd(project)]}
                      onContextMenu={ev => {
                        ev.preventDefault()
                        setContextualMenuOpen(true)
                        setContextualMenuTarget(ev)
                        setContextualMenuProject(project)
                      }}
                    />
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ))}
        <ProjectOptionsMenu
          project={contextualMenuProject}
          open={contextualMenuOpen}
          onOpenChange={onContextMenuOpenChange}
          positioning={{ target: contextualMenuTarget }}
        />
      </nav>
      <ProjectsFiltersPanel
        isOpen={filtersPanelOpen}
        onDismiss={hideFiltersPanel}
        mode={FILTER_REDUCER_KEYS.PROJECTS_PANEL}
      />
    </aside>
  )
}

const MemoizedProjectsPanel_ = memo(ProjectsPanel_)

export const ProjectsPanel = props => {
  const showItemCounts = useSelector(getProjectsPanelShowItemCounts)
  const { refetch, isRefetching, isPending, data } = useAllProjectCounters(showItemCounts, false)
  const deferredCounters = useDeferredValue(data)
  return (
    <MemoizedProjectsPanel_
      {...props}
      refetchCounters={refetch}
      isLoadingCounters={isRefetching || isPending}
      counters={deferredCounters}
    />
  )
}

const ProjectListCell = ({
  index,
  project,
  collapsed,
  selectedProjectId,
  onClick,
  onContextMenu,
  draggable = false,
  showItemCounts = false,
  counters,
}) => {
  const mode = useSelector(getPreferredMatrixViewMode)
  const id = projectHelper.getIdd(project)
  const name = projectHelper.getName(project)
  const selected = id === selectedProjectId

  return (
    <Draggable draggableId={id.toString()} index={index} isDragDisabled={!draggable}>
      {(provided, snapshot) => (
        <Tooltip
          content={name}
          positioning="after"
          withArrow
          relationship={collapsed ? 'label' : 'inaccessible'}
          visible={collapsed ? undefined : false}
        >
          <Link
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            to={getRelativePathToMatrixBasedOnMode(mode)(id)}
            key={projectHelper.getIdd(project)}
            className={cn(
              'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline',
              selected && 'bg-pm-appfluence'
            )}
            onClick={onClick}
            onContextMenu={onContextMenu}
            aria-current={selected ? 'page' : false}
          >
            <QuadrantSelector
              className={collapsed ? 'my-1' : 'self-start'}
              size={showItemCounts ? 40 : 20}
              readOnly
              project={project}
              showTooltips={!collapsed}
              counters={showItemCounts ? counters : undefined}
            />
            {!collapsed && <span className="min-w-0 break-words text-black dark:text-white">{name}</span>}
          </Link>
        </Tooltip>
      )}
    </Draggable>
  )
}
