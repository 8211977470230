import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { HomeAgenda } from './HomeAgenda'
import { HomeAlerts } from './HomeAlerts'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { linkSubject } from '../../reactions/linkSubject'
import { itemHelper } from '../../common/src/helpers'
import { HomeHeader } from './HomeHeader'
import { HomeRecents } from './HomeRecents'
import {
  clearAllAttentionNeededItems,
  clearCompletedAttentionNeededItems,
  clearOneDayAlerts,
  clearSevenDaysAlerts,
} from '../../common/src/actions/itemActions'
import { useDispatch, useSelector } from 'react-redux'
import { DROPDOWN_ICONS } from './HomeConstants'
import {
  clearItemMultipleSelection,
  setHomeAgendaMode,
  setHomeMyItemsMode,
  setMultipleSelectionOn,
} from '../../actions/uiActions'
import { SECTIONS } from './HomeConstants'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { navigationPaths } from '../../utils/NavPanelCommon'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { HomeMyItems } from './HomeMyItems'
import { AddItemButtonWithTooltip } from '../../components/buttons/AddItemButtonWithTooltip'
import { useReactToPrint } from 'react-to-print'
import { PrintableComponent } from '../../components/PrintableComponent'
import { getHomeAgendaMode, getHomeMyItemsMode } from '../../selectors/uiSelectors'
import { HOME_DROPDOWN_OPTIONS } from '../../actions/uiActions'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
import { useAlertItems } from './useAlertItems'
import { useRecentItems } from '../../hooks/itemHooks'
import { useAgendaItems } from '@/queries/agenda'
import { useMyItems } from './useMyItems'
import { ChevronDownRegular } from '@fluentui/react-icons'
import {
  Button,
  makeStyles,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Overflow,
  OverflowItem,
  Spinner,
  Tab,
  TabList,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { OverflowMenu } from '../../components/overflow/OverflowMenu'
import {
  Add,
  Alert,
  CalendarAgenda,
  CalendarToday,
  CalendarWorkWeek,
  CheckboxChecked,
  Dismiss,
  History,
  Person,
  Print,
} from '../../components/BundledIcons'
import { PrintButton } from '../../components/buttons/PrintButton'
import { isPrintHotKey, useHotkey } from '../../hooks/useHotkey'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'
import { META_KEY } from '../../components/shortcutHelp/ShortcutHelp'
import { motion } from 'framer-motion'
import { Subject, first } from 'rxjs'

const useSubject = () => {
  const [subject] = useState(new Subject())
  return subject
}

const useFinalSection = section => {
  const subject = useSubject()
  const { count: alertsCount, initialized: alertsInitialized } = useAttentionNeeded()
  const [bestInitialSection, setBestInitialSection] = useState(undefined)
  const validSection = _.find(SECTIONS, s => s === section)

  useEffect(() => {
    let alive = true
    const result = subject.pipe(first())
    const subs = result.subscribe(opt => {
      setBestInitialSection(opt)
    })
    setTimeout(() => {
      if (alive) {
        subject.next(SECTIONS.ALERTS)
      }
    }, 1000)

    return () => {
      subs.unsubscribe()
      alive = false
    }
  }, [subject, setBestInitialSection])

  useEffect(() => {
    if (alertsInitialized) {
      subject.next(alertsCount ? SECTIONS.ALERTS : SECTIONS.AGENDA)
    }
  }, [alertsInitialized, alertsCount, subject])

  if (validSection) return validSection
  return bestInitialSection
}

const useSection = ({ history, match }) => {
  const sectionParam = _.get(match, 'params.section')
  const sectionFinal = useFinalSection(sectionParam)
  const dispatch = useDispatch()
  useEffect(() => {
    if (sectionParam !== sectionFinal) {
      history.replace(getRelativeURLKeepingQuerySearch.homeSection(sectionFinal))
    }
  }, [sectionParam, history, sectionFinal])

  //tracking
  useEffect(() => {
    if (sectionFinal) {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_HOME, {
          section: sectionFinal,
        })
      )
    }
  }, [sectionFinal, dispatch])
  return sectionFinal
}

const PivotItemContent = props => (
  <div
    className="relative box-border flex h-full w-full flex-1 flex-col overflow-auto p-2"
    data-is-scrollable="true"
    {...props}
  />
)

const useClasses = makeStyles({
  menuButton: {
    minWidth: '48px',
  },
  chevronIcon: {
    fontSize: '12px',
    marginTop: '2px',
  },
})

const agendaOptionMapper = (setMode, dropdownOptions) => mode => {
  const Icon = DROPDOWN_ICONS[mode]
  return (
    <MenuItemRadio key={mode} name="mode" value={mode} icon={<Icon />} onClick={() => setMode(mode)}>
      {dropdownOptions[mode]}
    </MenuItemRadio>
  )
}

const MotionButton = motion(Button)

export const HomeMain = ({ match, history }) => {
  const classes = useClasses()
  const reactHistory = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // syncSection only for Pivot header, so that a tab change seems instant
  const syncSection = useSection({ history, match })
  const section = useDeferredValue(syncSection)
  const agendaMode = useSelector(getHomeAgendaMode)
  const myItemsMode = useSelector(getHomeMyItemsMode)
  const setAgendaMode = useCallback(mode => dispatch(setHomeAgendaMode(mode)), [dispatch])
  const setMyItemsMode = useCallback(mode => dispatch(setHomeMyItemsMode(mode)), [dispatch])
  const isMobileVersion = useNarrowWidth()
  const createItemModal = useCreateItemModal()
  const { count: alertCount } = useAttentionNeeded()
  const agendaRef = useRef()
  const [forceAlertId, setForceAlertId] = useState(0)
  const alerts = useAlertItems(forceAlertId)
  const recentItems = useRecentItems()
  const { items: agendaItems } = useAgendaItems(agendaMode)
  const myItems = useMyItems(myItemsMode)
  const narrow = useNarrowWidth()

  const items = {
    [SECTIONS.ALERTS]: alerts,
    [SECTIONS.RECENT]: recentItems,
    [SECTIONS.AGENDA]: agendaItems,
    [SECTIONS.MY_ITEMS]: myItems,
  }

  const handlePrintAgenda = useReactToPrint({
    content: () => agendaRef.current,
  })

  useSearchKeyPress(
    useCallback(() => {
      reactHistory.push(navigationPaths.search)
    }, [reactHistory])
  )

  useEffect(() => {
    dispatch(clearItemMultipleSelection())
    dispatch(setMultipleSelectionOn(false))
  }, [section, dispatch])

  const onSelectItem = useCallback(
    item => {
      linkSubject.next({
        urlData: itemHelper.getUriData(item),
        section,
      })
    },
    [section]
  )

  const onSelectGraphResource = useCallback(
    resource => {
      if (narrow) {
        history.push(getRelativeURLKeepingQuerySearch.graphResourceViewInAppForID(resource.id))
      } else {
        history.replace(getRelativeURLKeepingQuerySearch.homeSection(section, `graph-${resource.id}`))
      }
    },
    [narrow, history, section]
  )

  const handleTabSelect = useCallback(
    tabId => {
      history.push(getRelativeURLKeepingQuerySearch.homeSection(tabId))
      setForceAlertId(0)
    },
    [history]
  )

  const tabs = useMemo(
    () => [
      {
        id: SECTIONS.ALERTS,
        name: t('home.alerts') + (alertCount ? ` (${alertCount >= 1000 ? '>999' : alertCount})` : ''),
        icon: <Alert />,
      },
      {
        id: SECTIONS.MY_ITEMS,
        name: t('home.my_items'),
        icon: <Person />,
      },
      {
        id: SECTIONS.AGENDA,
        name: t('home.agenda'),
        icon: <CalendarAgenda />,
      },
      {
        id: SECTIONS.RECENT,
        name: t('home.recently_visited'),
        icon: <History />,
      },
    ],
    [t, alertCount]
  )

  const dropdownOptions = useMemo(() => {
    return {
      [HOME_DROPDOWN_OPTIONS.OWNED]: t('item_filters.is_owned'),
      [HOME_DROPDOWN_OPTIONS.FOLLOWED]: t('item_filters.is_followed'),
      [HOME_DROPDOWN_OPTIONS.EVERYTHING]: t('item_filters.all_items'),
    }
  }, [t])

  const optionMenuItems = useMemo(() => {
    switch (section) {
      case SECTIONS.ALERTS:
        return (
          <>
            <MenuItem
              onClick={() => {
                dispatch(clearCompletedAttentionNeededItems())
              }}
              icon={<CheckboxChecked />}
            >
              {t('alerts.clear_finished')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(clearSevenDaysAlerts())
              }}
              icon={<CalendarWorkWeek />}
            >
              {t('alerts.clear_seven_days')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(clearOneDayAlerts())
              }}
              icon={<CalendarToday />}
            >
              {t('alerts.clear_one_day')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                dispatch(clearAllAttentionNeededItems())
              }}
              icon={<Dismiss />}
            >
              {t('alerts.clear_all')}
            </MenuItem>
          </>
        )
      case SECTIONS.AGENDA:
        return (
          <>
            {[HOME_DROPDOWN_OPTIONS.OWNED, HOME_DROPDOWN_OPTIONS.FOLLOWED, HOME_DROPDOWN_OPTIONS.EVERYTHING].map(
              agendaOptionMapper(setAgendaMode, dropdownOptions)
            )}
          </>
        )
      case SECTIONS.MY_ITEMS:
        return (
          <>
            {[HOME_DROPDOWN_OPTIONS.OWNED, HOME_DROPDOWN_OPTIONS.FOLLOWED].map(
              agendaOptionMapper(setMyItemsMode, dropdownOptions)
            )}
          </>
        )
      default:
        return null
    }
  }, [section, t, setAgendaMode, dropdownOptions, setMyItemsMode, dispatch])

  if (!section) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  const OptionMenuIcon =
    section === SECTIONS.AGENDA
      ? DROPDOWN_ICONS[agendaMode]
      : section === SECTIONS.MY_ITEMS
        ? DROPDOWN_ICONS[myItemsMode]
        : Dismiss

  const handleAddItem = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.HOME_TOP_BAR,
      displayProjectSelector: true,
      quadrant: 0,
      open: false,
      completion: i => {
        linkSubject.next({
          urlData: itemHelper.getUriData(i),
          section,
        })
      },
    })
  }

  const menuButtonTooltipKey = section === SECTIONS.ALERTS ? 'home.clear_alerts_tooltip' : 'home.owner_filter_tooltip'

  return (
    <div className="box-border flex h-full w-full flex-1 flex-col bg-pm-neutral-lighter px-5 py-3">
      <HomeHeader />
      <div className="relative flex min-h-0 flex-1 flex-col rounded-[5px] bg-pm-white">
        <div className="flex">
          <div className="min-w-0 flex-1">
            <Overflow>
              <TabList selectedValue={syncSection} onTabSelect={(_, data) => handleTabSelect(data.value)}>
                {tabs.map(tab => (
                  <OverflowItem key={tab.id} id={tab.id} priority={tab.id === syncSection ? 2 : 1}>
                    <Tab
                      value={tab.id}
                      icon={tab.icon}
                      content={{ children: tab.name, className: 'hidden md:block' }}
                    />
                  </OverflowItem>
                ))}
                <OverflowMenu onTabSelect={handleTabSelect} tabs={tabs} />
              </TabList>
            </Overflow>
          </div>
          <div className="mr-1 flex items-center">
            {!isMobileVersion && (
              <>
                <AddItemButtonWithTooltip
                  className="mx-2"
                  id={'homeTopBar_addItemButton'}
                  key={'AddButton'}
                  text={t('item.create_item')}
                  onClick={handleAddItem}
                />
                {section === SECTIONS.AGENDA && <PrintAgendaButton onPrintAgenda={handlePrintAgenda} />}
                {section !== SECTIONS.AGENDA && <PrintButton items={items[section]} appearance="subtle" />}
              </>
            )}
            <TopBarMultiSelectButton allItems={items[section]} appearance="subtle" />
            {optionMenuItems && (
              <Menu
                checkedValues={{ mode: section === SECTIONS.MY_ITEMS ? myItemsMode : agendaMode }}
                positioning={{ autoSize: true }}
              >
                <Tooltip content={t(menuButtonTooltipKey)} relationship="label">
                  <MenuTrigger>
                    <MenuButton
                      className={classes.menuButton}
                      appearance="subtle"
                      icon={
                        <span className="flex items-center gap-1">
                          <OptionMenuIcon />
                          <ChevronDownRegular className={classes.chevronIcon} />
                        </span>
                      }
                    />
                  </MenuTrigger>
                </Tooltip>
                <MenuPopover>
                  <MenuList>{optionMenuItems}</MenuList>
                </MenuPopover>
              </Menu>
            )}
          </div>
        </div>
        <PivotItemContent>
          {section === SECTIONS.ALERTS && (
            <HomeAlerts
              onSelectItem={onSelectItem}
              setForceAlertId={setForceAlertId}
              items={alerts}
              agendaMode={agendaMode}
            />
          )}
          {section === SECTIONS.RECENT && <HomeRecents onSelectItem={onSelectItem} items={recentItems} />}
          {section === SECTIONS.AGENDA && (
            <PrintableComponent pageName={t('home.agenda')} ref={agendaRef}>
              <HomeAgenda onSelectItem={onSelectItem} mode={agendaMode} onSelectGraphResource={onSelectGraphResource} />
            </PrintableComponent>
          )}
          {section === SECTIONS.MY_ITEMS && (
            <HomeMyItems onSelectItem={onSelectItem} mode={myItemsMode} items={myItems} />
          )}
        </PivotItemContent>
        {isMobileVersion && (
          <div className="absolute bottom-8 right-8 z-50 scale-125">
            <MotionButton
              appearance="primary"
              size="large"
              whileTap={{ scale: 0.9 }}
              icon={<Add />}
              onClick={handleAddItem}
              shape="circular"
            />
          </div>
        )}
      </div>
    </div>
  )
}

const PrintAgendaButton = ({ onPrintAgenda }) => {
  const { t } = useTranslation()
  useHotkey(isPrintHotKey, onPrintAgenda)
  return (
    <Tooltip
      content={<ShortcutTooltipContent label={t('home.print_agenda')} tooltipKeys={[META_KEY, 'P']} />}
      relationship="label"
    >
      <Button icon={<Print />} onClick={onPrintAgenda} appearance="subtle" />
    </Tooltip>
  )
}
