import { useItemContextualMenuOptions } from '../../hooks/itemContextualMenu'
import {
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuSplitGroup,
  MenuTrigger,
} from '@fluentui/react-components'

// In this component we map the options returned by the hook to the Fluent UI Menu component
export const ItemContextualMenu = ({ itemId, blackList = [], allItemsInList, open = true, onOpenChange, target }) => {
  const sections = useItemContextualMenuOptions(itemId, { blackList, allItemsInList })
  return (
    <Menu open={open} onOpenChange={onOpenChange} positioning={{ target, autoSize: true }}>
      <MenuPopover>
        <MenuList>
          {sections.map(section => (
            <MenuGroup key={section.key}>
              {section.sectionProps.title && <MenuGroupHeader>{section.sectionProps.title}</MenuGroupHeader>}
              {section.sectionProps.items.map(item => {
                if (item.type === 'divider') {
                  return <MenuDivider key={item.key} />
                }
                if (!item.subMenuProps) {
                  return (
                    <MenuItem key={item.key} icon={item.icon} onClick={item.onClick} disabled={item.disabled}>
                      {item.text}
                    </MenuItem>
                  )
                }
                return (
                  <Menu key={item.key}>
                    {!item.split && (
                      <MenuTrigger disableButtonEnhancement>
                        <MenuItem icon={item.icon}>{item.text}</MenuItem>
                      </MenuTrigger>
                    )}
                    {item.split && (
                      <MenuSplitGroup>
                        <MenuItem icon={item.icon} onClick={item.onClick}>
                          {item.text}
                        </MenuItem>
                        <MenuTrigger disableButtonEnhancement>
                          <MenuItem aria-label={item.splitAriaLabel} />
                        </MenuTrigger>
                      </MenuSplitGroup>
                    )}
                    <MenuPopover>
                      <MenuList>
                        {item.subMenuProps.items.map(item => (
                          <MenuItem key={item.key} icon={item.icon} onClick={item.onClick}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                )
              })}
            </MenuGroup>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
